import React from 'react';
import backgroundImage from '../images/lumin-background.jpg';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner'; // 引入 CookieBanner 组件
import { ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const ThankYou = () => {
  return (
    <div className="min-h-screen bg-black text-white font-sans relative overflow-x-hidden">
      {/* Background Image */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0" 
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      
      {/* Gradient Blur Effect */}
      <div 
        className="fixed inset-0 z-0" 
        style={{
          backdropFilter: 'blur(10px)',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)',
        }}
      ></div>

      {/* Content Wrapper */}
      <div className="relative z-10">
        <Header />

        {/* Hero Section */}
        <header className="flex flex-col items-center justify-center text-center min-h-screen px-4 pt-20">
          <h1 className="text-5xl md:text-7xl font-bold mb-6">Thank You</h1>
          <p className="text-xl md:text-2xl mb-10 max-w-2xl">Companion that understands, supports and grows with you</p>
          <Link to="/" className="bg-white text-black px-8 py-3 rounded-full text-lg font-semibold hover:bg-opacity-80 transition flex items-center">
            Back to Home
            <ChevronRight className="ml-2" />
          </Link>
        </header>

        {/* Cookie Banner */}
        <CookieBanner /> {/* 使用 CookieBanner 组件 */}

        {/* Features Section */}
        <section className="py-20 px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">How LUMIN Supports You</h2>
          <div className="grid md:grid-cols-3 gap-12 max-w-6xl mx-auto">
          <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Deep Understanding</h3>
              <p className="text-gray-300">LUMIN learns and adapts to your unique personality and needs</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Emotional Support</h3>
              <p className="text-gray-300">Always here to listen, comfort, and offer different perspectives</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Personal Growth</h3>
              <p className="text-gray-300">Encourages and guides you towards achieving your goals and aspirations</p>
            </div>
          </div>
        </section>

        <Footer />

        {/* Debugging Text */}
        <div className="text-center text-red-500">
          <p>Debug: Thank You Page Loaded</p>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
