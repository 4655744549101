import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import backgroundImage from '../images/lumin-background.jpg';
import { ChevronRight } from 'lucide-react';

const TryForFree = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Please wait for the email.');
    // 这里可以添加表单提交逻辑
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans relative overflow-x-hidden">
      {/* Background Image */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0" 
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      
      {/* Gradient Blur Effect */}
      <div 
        className="fixed inset-0 z-0" 
        style={{
          backdropFilter: 'blur(10px)',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 100%, rgba(0,0,0,0.7) 100%)',
        }}
      ></div>

      {/* Content Wrapper */}
      <div className="relative z-10 p-4">
        <Header />

        <h1 className="text-3xl font-bold mb-6">Try for Free</h1>
        <p className="mb-6">Here is a brief introduction about the free trial...</p>
        <form onSubmit={handleSubmit} className="bg-white p-6 rounded shadow-md w-full max-w-md">
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">What you want</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              required
            />
          </div>
          <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
            Submit
          </button>
        </form>
        
        <Footer />
      </div>
    </div>
  );
};

export default TryForFree;
