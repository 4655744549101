import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { initializeApp } from 'firebase/app'; // Import Firebase app
import ReCAPTCHA from 'react-google-recaptcha'; // Import ReCAPTCHA
import backgroundImage from '../images/lumin-background.jpg';
import Header from './Header';
import Footer from './Footer';
import CookieBanner from './CookieBanner';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBafcSIegEQubjCfPidbT0Z_1DYa3maGmQ",
    authDomain: "luminlife-6eed0.firebaseapp.com",
    projectId: "luminlife-6eed0",
    storageBucket: "luminlife-6eed0.appspot.com",
    messagingSenderId: "488315749716",
    appId: "1:488315749716:web:555aa1e64126740dbeb4c1",
    measurementId: "G-ZH6PTHSXTF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const Contact = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [captchaValue, setCaptchaValue] = useState(null); // State for reCAPTCHA value

  const isSubmitDisabled = !email || !captchaValue; // Disable submit if email or captcha is not filled

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      alert("Please complete the reCAPTCHA challenge.");
      return;
    }
    console.log('Form submitted with captcha token:', captchaValue);
    try {
      // Add email to Firestore
      await addDoc(collection(db, 'emails'), { email });
      navigate('/thank-you'); // Redirect to Thank You page
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white font-sans relative overflow-x-hidden">
      {/* Background Image */}
      <div 
        className="fixed inset-0 bg-cover bg-center z-0" 
        style={{
          backgroundImage: `url(${backgroundImage})`,
        }}
      ></div>
      
      {/* Gradient Blur Effect */}
      <div 
        className="fixed inset-0 z-0" 
        style={{
          backdropFilter: 'blur(10px)',
          background: 'linear-gradient(to bottom, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 100%)',
        }}
      ></div>

      {/* Content Wrapper */}
      <div className="relative z-10">
        <Header />

        {/* Hero Section */}
        <header className="flex flex-col items-center justify-center text-center min-h-screen px-4 pt-20">
          <h1 className="text-5xl md:text-7xl font-bold mb-6">Try for free</h1>
          <p className="text-xl md:text-2xl mb-10 max-w-2xl">Please tell us your email, and we will keep in touch with you.</p>
          <form onSubmit={handleSubmit} className="mb-10 flex flex-col items-center">
            <div className="flex items-center">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full max-w-lg p-3 border border-white rounded bg-transparent placeholder-white focus:outline-none focus:border-white"
                placeholder="Enter your email"
                required
              />
              <button 
                type="submit" 
                className={`px-8 py-3 rounded text-lg font-semibold transition ml-2 ${isSubmitDisabled ? 'bg-gray-300 text-gray-200 cursor-not-allowed' : 'bg-white text-black hover:bg-opacity-80'}`} // Change styles based on disabled state
                disabled={isSubmitDisabled} // Disable button based on conditions
              >
                Submit
              </button>
            </div>
            <ReCAPTCHA
              sitekey="6LcTn20qAAAAAJwT-Yz8rL51CR50PPP_PufqQsic" // Replace with your reCAPTCHA site key
              onChange={(value) => setCaptchaValue(value)} // Set the reCAPTCHA value
              className="mt-4"
            />
          </form>
        </header>

        {/* Cookie Banner */}
        <CookieBanner />

        {/* Features Section */}
        <section className="py-20 px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl font-bold mb-12 text-center">How LUMIN Supports You</h2>
          <div className="grid md:grid-cols-3 gap-12 max-w-6xl mx-auto">
            <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Deep Understanding</h3>
              <p className="text-gray-300">LUMIN learns and adapts to your unique personality and needs</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Emotional Support</h3>
              <p className="text-gray-300">Always here to listen, comfort, and offer different perspectives</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-20 h-20 bg-white bg-opacity-20 rounded-full mb-6 flex items-center justify-center">
                <svg className="w-12 h-12 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
              </div>
              <h3 className="text-xl font-semibold mb-3">Personal Growth</h3>
              <p className="text-gray-300">Encourages and guides you towards achieving your goals and aspirations</p>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};

export default Contact;
