import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LuminAIHomepage from './components/LuminAIHomepage';
import TryForFree from './components/TryForFree';
import Contact from './components/Contact';
import ThankYou from './components/ThankYou';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LuminAIHomepage />} />
        <Route path="/try-for-free" element={<TryForFree />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/thank-you" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App;
