import React, { useState, useEffect } from 'react';
import { Menu } from 'lucide-react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out ${
      isScrolled ? 'bg-black bg-opacity-90 shadow-lg' : 'bg-transparent'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-6">
          <Link to="/" className="text-2xl font-bold text-white">LUMIN</Link>
          <div className="hidden md:flex space-x-8 items-center">
            <Link to="/" className={`hover:text-gray-300 transition ${isScrolled ? 'text-white' : 'text-white'}`}>Home</Link>
            <Link to="/contact" className={`hover:text-gray-300 transition ${isScrolled ? 'text-white' : 'text-white'}`}>Contact</Link>
            <div className="flex-grow"></div>
          </div>
          <Menu className="md:hidden" />
        </div>
      </div>
    </nav>
  );
};

export default Header;
