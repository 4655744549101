import React from 'react';

const Footer = () => {
  return (
    <footer className="py-8 px-4 sm:px-6 lg:px-8 text-center bg-black bg-opacity-50 mt-auto">
      <p className="text-white">&copy; 2024 LUMIN. Your companion in growth and understanding.</p>
    </footer>
  );
};

export default Footer;
