import React, { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    const cookiesAccepted = document.cookie.split('; ').find(row => row.startsWith('cookiesAccepted='));
    if (!cookiesAccepted) {
      setShowCookieBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true; path=/; max-age=" + 60 * 60 * 24 * 30; // 30天
    setShowCookieBanner(false);
  };

  if (!showCookieBanner) return null;

  return (
    <div className="bg-black bg-opacity-90 shadow-lg fixed bottom-0 left-0 right-0 text-white p-4 flex justify-between items-center">
      <span>We use cookies to improve your experience. By using our site, you agree to our cookie policy.</span>
      <button onClick={acceptCookies} className="bg-white text-black px-8 py-1 rounded-sm text-lg hover:bg-opacity-80 transition flex items-center">
        Accept
      </button>
    </div>
  );
};

export default CookieBanner;
